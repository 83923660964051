(function() {
    'use strict';

    angular.module('ionicNewsfeed', [
        'ionic',
        'ionicAlert',
        'ionicConfirmation',
        'ionicData',
        'ionicForceRefresh',
        'ionicInfiniteScroll',
        'ionicLogin',
        'ionicPost',
        'ionicUser',
        'uabDefaultImage',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicNewsfeed').service('NewsfeedService', NewsfeedService);

    NewsfeedService.$inject = [
        'ListManagementService',
        'NewsfeedPostCreateService',
        'NewsfeedPostEditService',
        'NewsfeedPostListService'
    ];

    function NewsfeedService(
        ListManagementService,
        NewsfeedPostCreateService,
        NewsfeedPostEditService,
        NewsfeedPostListService
    ) {
        var NewsfeedService = this;

        NewsfeedService.createPost = createPost;
        function createPost(newPost) {
            return NewsfeedPostCreateService.createPost(newPost);
        }

        NewsfeedService.deletePost = deletePost;
        function deletePost(post) {
            return NewsfeedPostEditService.deletePost(post);
        }

        NewsfeedService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        NewsfeedService.getNewsfeed = getNewsfeed;
        function getNewsfeed() {
            return NewsfeedPostListService.loadNewsfeed();
        }

        NewsfeedService.loadNewsfeed = loadNewsfeed;
        function loadNewsfeed(options) {
            return NewsfeedPostListService.getMore(options);
        }

        NewsfeedService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = NewsfeedPostListService.getListManager();

        NewsfeedService.reset();

        return NewsfeedService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNewsfeed').service('NewsfeedPostService', NewsfeedPostService);

    NewsfeedPostService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'ListManagementService',
        'LoginService',
        'NewsfeedPostListService',
        'RequestManagementService',
        'UserService'
    ];

    function NewsfeedPostService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        ListManagementService,
        LoginService,
        NewsfeedPostListService,
        RequestManagementService,
        UserService
    ) {
        var NewsfeedPostService = this;

        NewsfeedPostService.getListManager = getListManager;
        function getListManager() {
            return NewsfeedPostListService.getListManager();
        }

        NewsfeedPostService.getPost = getPost;
        function getPost(post) {
            if (!DefaultVariableService.isObject(post)) {
                var postId = EnvironmentService.get('post_id');
                if (postId) {
                    return NewsfeedPostService.getPost({ id: postId });
                } else {
                    return UserService.getUser().then(
                        function(user) {
                            var userId = 0;
                            if (user) {
                                userId = user.id;
                            }

                            return NewsfeedPostService.getListManager(
                                userId
                            ).getDefault().then(
                                function(post) {
                                    if (post) {
                                        return NewsfeedPostService.getPost(post);
                                    }

                                    return post;
                                }
                            );
                        }
                    );
                }
            }

            return NewsfeedPostService.loadPost(post);
        }

        NewsfeedPostService.loadPost = loadPost;
        function loadPost(post) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'posts');
            options = RequestManagementService.setAction(options, 'get', post.id);

            options = RequestManagementService.setParams(
                options,
                [
                    'Comments.Users',
                    'Images',
                    'PostLikes',
                    'FromUser',
                    'ToUser'
                ]
            );

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        return data.posts[0];
                    }

                    return data;
                }
            );
        }

        NewsfeedPostService.reset = reset;
        function reset() {

        }

        return NewsfeedPostService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNewsfeed').service(
        'NewsfeedPostCreateService',
        NewsfeedPostCreateService
    );

    NewsfeedPostCreateService.$inject = [
        'DefaultVariableService',
        'LoginService',
        'NewsfeedPostListService',
        'RequestManagementService'
    ];

    function NewsfeedPostCreateService(
        DefaultVariableService,
        LoginService,
        NewsfeedPostListService,
        RequestManagementService
    ) {
        var NewsfeedPostCreateService = this;

        NewsfeedPostCreateService.createPost = createPost;
        function createPost(newPost) {
            if (!DefaultVariableService.isObject(newPost.to_user) || !DefaultVariableService.isObject(newPost.from_user)) {
                return LoginService.getActiveUser().then(
                    function(activeUser) {
                        if (activeUser) {
                            newPost.to_user = activeUser;
                            newPost.to_user_id = activeUser.id;

                            newPost.from_user = activeUser;
                            newPost.from_user_id = activeUser.id;

                            return NewsfeedPostCreateService.createPost(newPost);
                        }

                        return activeUser;
                    }
                );
            }

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'posts');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, newPost);

            return ListManager.create(options).then(
                function() {
                    ListManager.updateInList(
                        newPost
                    );

                    return newPost;
                }
            );
        }

        NewsfeedPostCreateService.reset = reset;
        function reset() {

        }

        var ListManager = NewsfeedPostListService.getListManager();

        NewsfeedPostCreateService.reset();

        return NewsfeedPostCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNewsfeed').service('NewsfeedPostEditService', NewsfeedPostEditService);

    NewsfeedPostEditService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'NewsfeedPostListService',
        'NewsfeedPostService',
        'RequestManagementService'
    ];

    function NewsfeedPostEditService(
        DefaultVariableService,
        EnvironmentService,
        NewsfeedPostListService,
        NewsfeedPostService,
        RequestManagementService
    ) {
        var NewsfeedPostEditService = this;

        NewsfeedPostEditService.deletePost = deletePost;
        function deletePost(post) {
            post = DefaultVariableService.getObject(
                post
            );

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'posts');
            options = RequestManagementService.setAction(options, 'delete', post.id);

            return ListManager.remove(options);
        }

        NewsfeedPostEditService.editPost = editPost;
        function editPost(post) {
            post = DefaultVariableService.getObject(
                post
            );

            delete post.from_user;
            delete post.to_user;

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'posts');
            options = RequestManagementService.setAction(options, 'edit');
            options = RequestManagementService.setSubAction(options, post.id);

            options = RequestManagementService.setData(options, post);

            return ListManager.edit(options);
        }

        NewsfeedPostEditService.getPost = getPost;
        function getPost(post) {
            if (!DefaultVariableService.isObject(post)) {
                var postId = EnvironmentService.get('post_id');
                if (postId) {
                    return NewsfeedPostService.loadPost({ id: postId });
                } else {
                    return ListManager.getDefault().then(
                        function(post) {
                            if (post) {
                                return NewsfeedPostService.loadPost(post);
                            }

                            return post;
                        }
                    );
                }
            }

            return NewsfeedPostService.loadPost(post);
        }

        NewsfeedPostEditService.reset = reset;
        function reset() {

        }

        var ListManager = NewsfeedPostListService.getListManager();

        NewsfeedPostEditService.reset();

        return NewsfeedPostEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNewsfeed').service('NewsfeedPostListService', NewsfeedPostListService);

    NewsfeedPostListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];

    function NewsfeedPostListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var NewsfeedPostListService = this;

        NewsfeedPostListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        NewsfeedPostListService.getNewsfeed = getNewsfeed;
        function getNewsfeed() {
            return NewsfeedPostListService.loadNewsfeed();
        }

        NewsfeedPostListService.loadNewsfeed = loadNewsfeed;
        function loadNewsfeed(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'posts');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'Comments.Users',
                    'Images',
                    'PostLikes',
                    'FromUser',
                    'ToUser'
                ]
            );

            options = RequestManagementService.setOrder(
                options,
                [
                    '-Posts.created'
                ]
            );

            return ListManager.getMore(options);
        }

        NewsfeedPostListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            NewsfeedPostListService.getNewsfeed
        );

        NewsfeedPostListService.reset();

        return NewsfeedPostListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNewsfeed').controller('NewsfeedController', NewsfeedController);

    NewsfeedController.$inject = [
        'DefaultVariableService',
        '$ionicScrollDelegate',
        'NewsfeedService',
        'LoginService'
    ];

    function NewsfeedController(
        DefaultVariableService,
        $ionicScrollDelegate,
        NewsfeedService,
        LoginService
    ) {
        var NewsfeedController = this;

        NewsfeedController.clear = clear;
        function clear() {
            NewsfeedService.reset();
            NewsfeedController.reset();
        }

        NewsfeedController.createPost = createPost;
        function createPost(newPost) {
            return NewsfeedService.createPost(newPost);
        }

        NewsfeedController.deletePost = deletePost;
        function deletePost(post) {
            return NewsfeedService.deletePost(post);
        }

        NewsfeedController.forceRefresh = forceRefresh;
        function forceRefresh() {
            NewsfeedController.clear();
            NewsfeedController.init();
        }

        NewsfeedController.loadNewsfeed = loadNewsfeed;
        function loadNewsfeed() {
            NewsfeedController.isLoadingNewsfeed = true;

            return NewsfeedService.getNewsfeed().then(
                function(data) {
                    if (data) {
                        NewsfeedController.posts = data.posts;
                    }

                    NewsfeedController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMore,
                        false
                    );

                    return data;
                }
            ).finally(
                function() {
                    NewsfeedController.isLoadingNewsfeed = false;
                }
            );
        }

        NewsfeedController.reset = reset;
        function reset() {
            NewsfeedController.canLoadMore = false;

            NewsfeedController.isLoadingNewsfeed = true;

            NewsfeedController.newPostPlaceholder = DefaultVariableService.get(
                NewsfeedController.newPostPlaceholder,
                'POST TO NEWSFEED'
            );

            NewsfeedController.postSref = DefaultVariableService.get(
                NewsfeedController.postSref,
                'app.post'
            );

            NewsfeedController.userSref = DefaultVariableService.get(
                NewsfeedController.userSref,
                'app.user'
            );
        }

        NewsfeedController.init = init;
        function init() {
            NewsfeedController.reset();
            NewsfeedController.loadNewsfeed();
        }

        LoginService.register(NewsfeedController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNewsfeed').directive('ionicNewsfeed', ionicNewsfeed);

    function ionicNewsfeed() {
        return {
            bindToController: {
                newPostPlaceholder: '@',
                postSref:           '@',
                userSref:           '@'
            },
            controller:   'NewsfeedController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<ion-list><ionic-force-refresh refresh-function="ctrl.forceRefresh"></ionic-force-refresh><ionic-post-create on-create="ctrl.createPost" expand="false" placeholder="{{ ctrl.newPostPlaceholder }}"></ionic-post-create><div class="row"><ionic-post class="col-lg-6" data-ng-repeat="post in ctrl.posts" expand-comments="false" on-delete="ctrl.deletePost" post="post" post-sref="app.post" show-new-comment="false" show-spinner="false" user-sref="app.profile"></ionic-post></div><div data-ng-if="!ctrl.canLoadMore && !ctrl.isLoadingNewsfeed" class="bar bar-stable"><h1 class="title">NO {{ ctrl.posts.length === 0 ? \'\' : \'MORE\' }} POSTS</h1></div><ionic-infinite-scroll can-load-more="ctrl.canLoadMore" immediate-check="false" is-loading="ctrl.isLoadingNewsfeed" load-more="ctrl.loadNewsfeed"></ionic-infinite-scroll></ion-list>'
        };
    }
})();